@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR&display=swap');

body    {
    font-family: 'Noto Sans KR', sans-serif;
    color: #21252B
}

h1  {

}

h4  {
    color: #21252B
}

h5  {
    color: #000000;
}

.quote  {
    color: chocolate;
    text-align: right;
}

.splashContainer    {
    background-color: #e9e9e9;
    padding-bottom: 25px
}

.projectContainer    {
    background-color: #d7d7d4;
}


.profileImage   {
    border-radius: 35%;
}

.splashTop  {
    padding-top: 4%;
    padding-bottom: 30px;
}

.category_single h4  {
    background-color: #93cee9;
    margin-bottom: 0;
    font-size: 20px;
    padding: 10px;
}

.category_single div{
    text-align: right;
}

.categoryCol    {
    padding: 2px;
}

.category_single span  {
    background-color: #EEC370;
    padding: 5px;
    font-size: 14px;
}

.contactDetails {
    font-size: 12pt;
    display: inline-block;
    text-align: left;
    padding-left: 10px;
}

.contactDetails a{
    color: chocolate;
    text-decoration: underline;

}

.pictureDiv {
    padding-top: 20px; padding-bottom: 20px;
    padding-left: 0;
}

.projectSingle  {
    background-color: #ffffff;
}

.readMore{
    //float: left
}

.projectText    {
    color: #808080;
    margin-bottom: 0;
}

.projectCat{
    float: right;
    color: chocolate;
}

.projectUI  {
   padding-bottom: 20px;
    margin-top: 5px
}

.projectDuration    {
    color: #A9A9A9;
    float: left
    /*margin-left: auto;
    margin-right: auto;*/
}

.postContainer  {
    margin-top: 30px;
    padding-left: 70px;
    padding-right: 70px
}

footer  {
    padding-top: 5px
}
